import { request, proRequest } from '@/utils/request.js'
import { transformExpert, transformKnowledge } from '@/utils/transform';

// 获取案例列表
export function getExperts(params) {
    return request.get('/experts', { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformExpert(row))
        return Promise.resolve(temp)
    })
}

// 获取专家知识库
export function getExpertKnowledge(id, params) {
    return request.get(`/experts/${id}/knowledges`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformKnowledge(row))
        return Promise.resolve(temp)
    })
}

// 获取当前公司案例列表
export function getCurrentExperts() {
    return request.get('/user/company/experts')
}

// 获取当前公司案例详情
export function getCurrentExpertDetail(id) {
    return request.get(`/user/company/experts/${id}`)
}

// 获取公司案例列表
export function getCompanyExperts(id) {
    return request.get(`/company/${id}/experts`)
}

// 获取案例详情
export function getExpert(id) {
    return request.get(`/experts/${id}`).then(({ data }) => {
        return Promise.resolve(transformExpert(data))
    })
}

// 设置案例可见性
export function setExpertVisible(id, data) {
    return request.post(`/experts/${id}/visible`, data)
}

// 创建案例
export function createExpert(data) {
    return request.post(`/experts`, data)
}

// 保存案例
export function saveExpert(data) {
    return request.put(`/experts/${data.ID}`, data)
}

// 删除案例
export function deleteExpert(id) {
    return request.delete(`/experts/${id}`)
}

// 提交案例
export function evaluateExpert(id) {
    return request.post(`/experts/${id}/submit`)
}

// 获取后台案例审核列表
export function getProExperts(params) {
    return proRequest.get(`/experts`, { params })
}

// 获取企业案例审核列表
export function getProCompanyExperts(id) {
    return proRequest.get(`/company/${id}/experts`)
}

// 获取后台案例详情
export function getProExpertDetail(id) {
    return proRequest.get(`/experts/${id}`)
}

// 保存后台案例
export function saveProExpert(id, data) {
    return proRequest.put(`/experts/${id}`, data)
}

// 审核后台案例
export function evaluteProExpert(id, data) {
    return proRequest.post(`/experts/${id}/audit`, data)
}
